<script setup lang="ts">
import type { Category } from '~/src/classes.ts';

const modelValue = defineModel<string[]>({ required: true });

defineProps<{
    label: string;
    categories: Category[];
}>();

const toggleCategory = (category: string) => {
    modelValue.value = modelValue.value.includes(category)
        ? modelValue.value.filter((c) => c !== category)
        : [...modelValue.value, category];
};
</script>

<template>
    <div v-if="categories.length > 0" class="form-group">
        <label><strong><Spelling :text="label" /><T>quotation.colon</T></strong></label>
        <button
            v-for="category in categories"
            :key="category.key"
            type="button"
            :class="['badge border mx-1 text-decoration-none',
                     modelValue.includes(category.key) ? 'bg-primary text-white' : 'bg-light text-primary']"
            @click.prevent="toggleCategory(category.key)"
        >
            <Icon v-if="category.icon" :v="category.icon" />
            <Spelling :text="category.text" />
        </button>
    </div>
</template>
